var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container terms" }, [
      _c("div", { attrs: { id: "article" } }, [
        _c("strong", { staticClass: "tit_article" }, [
          _vm._v("취소 및 환불 규정")
        ]),
        _c("br"),
        _c("br"),
        _vm._v(
          "헤이비글은 의뢰자에게는 안정적인 행사,공연을 제공하고 전문가에게는 안정적인 섭외,수익을 제공하기 위해 "
        ),
        _c("br"),
        _vm._v(
          "아래와 같은 최소한의 보호 기간과 취소 보상을 정하여 의무화하고 있습니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit_article" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v("제1조 일반회원의 취소 및 환불 규정")
          ])
        ]),
        _c("br"),
        _vm._v(
          "· 일반회원이 결제/섭외를 취소하는 경우 아래의 규정을 따릅니다. "
        ),
        _c("br"),
        _vm._v(" 1. 등록한 행사일이 14일 이상 남았을 시 : 100% 환불 "),
        _c("br"),
        _vm._v(" 2. 등록한 행사일이 13일 이하 남았을 시 : 50% 환불 "),
        _c("br"),
        _vm._v(" 3. 등록한 행사일이 6일 이하 남았을 시 : 취소 및 환불 불가 "),
        _c("br"),
        _c("br"),
        _vm._v(
          "· 위약금이 발생하는 상황이지만 자연재해/천재지변/사고 등 불가피한 사유로 취소를 하는 경우, "
        ),
        _c("br"),
        _vm._v(
          " 규정과 별개로 전문가와 협의를 통해 위약금을 정할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "· 상호협의 후 해당 내용을 고객센터로 전달 시 협의된 내용으로 환불 및 취소가 가능합니다. "
        ),
        _c("br"),
        _vm._v(
          "· 일반회원의 요청으로 전문가가 물품,자료 구매 등 발생한 실비가 있을 시 "
        ),
        _c("br"),
        _vm._v(" 사실 확인 후 지급 및 보상하여야 합니다. "),
        _c("br"),
        _vm._v(
          "· 행사가 끝나고 대금 지급된 후에 환불을 요청하는 경우에는 회원 간 상호협의를 통해 진행하셔야 합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit_article" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v("제2조 엔터테이너(전문가)의 취소 및 위약금 규정")
          ])
        ]),
        _c("br"),
        _vm._v(
          "· 엔터테이너가 결제/섭외를 취소하거나 의뢰를 이행하지 않는 경우 아래의 규정을 따릅니다. "
        ),
        _c("br"),
        _vm._v(
          " 1. 등록한 행사일이 14일 이상 남았을 시 : 취소 가능 (배상 없음) "
        ),
        _c("br"),
        _vm._v(
          " 2. 등록한 행사일이 13일 이하 남았을 시 : 결제 금액의 50% 배상 "
        ),
        _c("br"),
        _vm._v(
          " 3. 등록한 행사일이 6일 이하 남았을 시 : 취소 불가 (취소 및 불참 시 결제 금액의 3배 배상) "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "· 위약금이 발생하는 상황이지만 자연재해/천재지변/사고/입원 등의 불가피한 사유로 취소를 하는 경우, "
        ),
        _c("br"),
        _vm._v(
          " 규정과 별개로 일반회원과 협의를 통해 위약금을 정할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "· 상호협의 후 해당 내용을 고객센터로 전달 시 협의된 내용으로 환불 및 취소가 가능합니다. "
        ),
        _c("br"),
        _vm._v(
          "· 위약금 여부와 상관없이 모든 취소에 대해 불가피하거나 정당,합당한 사유의 취소만 가능하며 "
        ),
        _c("br"),
        _vm._v(
          " 개인의 이득을 위한 취소 등 정당하지 않은 사유로 취소하는 경우 패널티가 부여됩니다. "
        ),
        _c("br"),
        _vm._v(
          "· 전문가는 고객센터에서 취소 관련 증빙 자료 요청 시 전달의 위무가 있으며 "
        ),
        _c("br"),
        _vm._v(
          " 제출하지 않을 경우 위약금이 발생하거나 패널티가 부여될 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "· 또한 이로 인한 서비스의 대외 이미지 실추 및 유,무형적 손해가 발생한 경우 배상하여야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "· 전문가가 취소 위약금을 회사 또는 일반회원에게 배상하지 않을 경우 회사는 민∙형사 상의 법적 조치 등을 취할 수 있습니다. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }