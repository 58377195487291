<template>
    <div class="container terms">
        <div id="article">
            <strong class="tit_article">취소 및 환불 규정</strong>

            <br><br>헤이비글은 의뢰자에게는 안정적인 행사,공연을 제공하고 전문가에게는 안정적인 섭외,수익을 제공하기 위해
            <br>아래와 같은 최소한의 보호 기간과 취소 보상을 정하여 의무화하고 있습니다.
            <br><br>
            <br><strong class="tit_article"><span class="tit">제1조 일반회원의 취소 및 환불 규정</span></strong>
            <br>· 일반회원이 결제/섭외를 취소하는 경우 아래의 규정을 따릅니다.
                <br>&emsp;1. 등록한 행사일이 14일 이상 남았을 시 : 100% 환불
                <br>&emsp;2. 등록한 행사일이 13일 이하 남았을 시 : 50% 환불
            <br>&emsp;3. 등록한 행사일이 6일 이하 남았을 시 : 취소 및 환불 불가
                <br><br>· 위약금이 발생하는 상황이지만 자연재해/천재지변/사고 등 불가피한 사유로 취소를 하는 경우,
                <br>&emsp;규정과 별개로 전문가와 협의를 통해 위약금을 정할 수 있습니다.
                <br>· 상호협의 후 해당 내용을 고객센터로 전달 시 협의된 내용으로 환불 및 취소가 가능합니다.
                <br>· 일반회원의 요청으로 전문가가 물품,자료 구매 등 발생한 실비가 있을 시
                <br>&emsp;사실 확인 후 지급 및 보상하여야 합니다.
                <br>· 행사가 끝나고 대금 지급된 후에 환불을 요청하는 경우에는 회원 간 상호협의를 통해 진행하셔야 합니다.

            <br><br><br><strong class="tit_article"><span class="tit">제2조 엔터테이너(전문가)의 취소 및 위약금 규정</span></strong>
                <br>· 엔터테이너가 결제/섭외를 취소하거나 의뢰를 이행하지 않는 경우 아래의 규정을 따릅니다.
                <br>&emsp;1. 등록한 행사일이 14일 이상 남았을 시 : 취소 가능 (배상 없음)
                <br>&emsp;2. 등록한 행사일이 13일 이하 남았을 시 : 결제 금액의 50% 배상
            <br>&emsp;3. 등록한 행사일이 6일 이하 남았을 시 : 취소 불가 (취소 및 불참 시 결제 금액의 3배 배상)
                <br><br>· 위약금이 발생하는 상황이지만 자연재해/천재지변/사고/입원 등의 불가피한 사유로 취소를 하는 경우,
                <br>&emsp;규정과 별개로 일반회원과 협의를 통해 위약금을 정할 수 있습니다.
                <br>· 상호협의 후 해당 내용을 고객센터로 전달 시 협의된 내용으로 환불 및 취소가 가능합니다.
                <br>· 위약금 여부와 상관없이 모든 취소에 대해 불가피하거나 정당,합당한 사유의 취소만 가능하며
                <br>&emsp;개인의 이득을 위한 취소 등 정당하지 않은 사유로 취소하는 경우 패널티가 부여됩니다.
            <br>· 전문가는 고객센터에서 취소 관련 증빙 자료 요청 시 전달의 위무가 있으며
            <br>&emsp;제출하지 않을 경우 위약금이 발생하거나 패널티가 부여될 수 있습니다.
            <br>· 또한 이로 인한 서비스의 대외 이미지 실추 및 유,무형적 손해가 발생한 경우 배상하여야 합니다.
            <br>· 전문가가 취소 위약금을 회사 또는 일반회원에게 배상하지 않을 경우 회사는 민∙형사 상의 법적 조치 등을 취할 수 있습니다.
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/etc.scss';
</style>
